import React from "react";

import AnalyzingImg from "../../assets/images/analyzing.gif";

const Analyzing = () => {
  return (
    <div className="absolute inset-0 bg-white px-8 py-12 flex flex-col items-center justify-center text-center">
      <img src={AnalyzingImg} alt="analyzing icon" className="w-48 mx-auto" />
      <p className="mt-3 text-primary text-xl">Analyzing Data</p>
      <p className="mt-2 text-primary font-light">
        Hold tight, the measurement
        <br />
        results are on the way
      </p>
    </div>
  );
};

export default Analyzing;
