import React from "react";

const SignalStrengthIndicator = ({ fps }) => {
  const signalStrength =
    fps > 15
      ? fps > 20
        ? { color: "#a0dd0c", label: "Good" }
        : { color: "#eea508", label: "Moderate" }
      : { color: "#f31e2e", label: "Poor" };

  return (
    <div
      className="rounded-full px-2 py-1 mt-4 text-xs text-white bg-[#f31e2e]"
      style={{
        backgroundColor: signalStrength.color,
      }}
    >
      Signal Strength:&nbsp;
      <span className="font-semibold">{signalStrength.label}</span>
    </div>
  );
};

export default SignalStrengthIndicator;
