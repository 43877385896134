import React from "react";
import CareflixLoaderGif from "../assets/images/CareflixLoader.gif";

const CareflixLoader = ({ style = {} }) => {
  return (
    <div
      className="min-h-screen flex justify-center items-center absolute top-0 left-0 z-10 w-screen bg-white"
      style={style}
    >
      <img
        src={CareflixLoaderGif}
        alt="carflix loader icon"
        className="w-[180px] h-[180px] object-contain mb-[30px]"
      />
    </div>
  );
};

export default CareflixLoader;
