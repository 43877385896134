import React, { useEffect, useState } from "react";
import { getUrlParams } from "../utils/helpers/common";
import FaceScanTwo from "./FaceScanTwo";

const FaceScanV3 = () => {
  const [urlParams, setUrlParams] = useState(null);

  useEffect(() => {
    const urlParams = getUrlParams();
    setUrlParams(urlParams);
    // eslint-disable-next-line
  }, []);

  const onComplete = () => {
    console.log("Scan completed...");
    window?.ReactNativeWebView?.postMessage("SCAN_COMPLETE");
  };

  const onError = (error = "SCAN_FAILED") => {
    console.log("Scan error...", error);
    window?.ReactNativeWebView?.postMessage(error);
  };

  return (
    <div className="w-full h-screen">
      <FaceScanTwo
        userInfo={urlParams}
        onComplete={onComplete}
        onError={onError}
      />
    </div>
  );
};

export default FaceScanV3;
