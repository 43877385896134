import { handleResponse } from "../utils/helper";
import { getEncryptedText } from "./hanlders";

export const request = async (url, payload) => {
  const requestPayload = getEncryptedText(payload);
  //   console.log("request", payload, requestPayload);
  const resp = await fetch(`${process.env.REACT_APP_BASE_URL}${url}`, {
    method: "POST",
    headers: {
      "api-key": process.env.REACT_APP_API_KEY,
    },
    body: requestPayload,
  });

  const resp_json = await handleResponse(resp, true);

  return resp_json;
};
