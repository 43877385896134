import React from "react";
import Failure from "../../assets/images/failure.gif";
import { useNavigate } from "react-router-dom";

const ScanFailed = () => {
  const navigate = useNavigate();
  return (
    <div className="absolute inset-0 bg-white px-8 py-12 flex flex-col items-center justify-center text-center">
      {window.navigator.onLine && (
        <img src={Failure} alt="failure icon" className="w-48 mx-auto" />
      )}
      <p className="mt-3 text-primary text-center">
        Scan Failed!
        <br />
        Please try again.
      </p>
      <button
        className="mt-8 px-4 py-2 rounded-lg bg-red-500 text-white text-sm font-medium"
        type="button"
        onClick={() => {
          navigate(-1);
        }}
      >
        Go Back
      </button>
    </div>
  );
};

export default ScanFailed;
