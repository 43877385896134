import React from "react";

const scanMessages = [
  "We burn calories while we are asleep because brain activity requires energy",
  "Exercise promotes cardiovascular health, ensuring more blood and oxygen to circulate the body, helping to elevate energy",
  "Always sleep on your back straight since it allows your neck and spine in a neutral position",
  "Alcohol consumption disrupts chemical impulses between brain cells causing impulsive behaviour, slurred speech, impaired memory",
  "Meditation increases nitric oxide generation in the brain helping to lower your blood pressure",
  "Meditation reduces the production of cytokines in several recent studies, thus helping in stress reduction",
  "Laughing is good for your heart. It reduces stress and gives a boost to your immune system",
  "Lack of sleep can lead to heart disease, heart attack, or stroke",
  "Exercise boosts brain performance",
  "Heart pumps about 2,000 gallons of blood every day",
  "Your heart will beat about 115,000 times each day",
].sort(() => Math.random() - 0.5);

const getMessage = (type = "", timeElapsed = 0, isLightMode = false) => {
  if (type !== "scan") {
    if (timeElapsed <= 5000)
      return "Keep your head upright and look straight at the camera";
    else if (timeElapsed <= 10000)
      return "During the measurement, please do not speak or move";
    else if (timeElapsed <= 17000)
      return "Keep your device steady throughout the vital measurement";
    else if (timeElapsed <= 18000) return "We are good to start in 3";
    else if (timeElapsed <= 19000) return "We are good to start in 2";
    else if (timeElapsed <= 20000) return "We are good to start in 1";
  } else {
    if (timeElapsed <= 30000)
      return isLightMode
        ? "Scan in Progress...\nSwitching to the light mode for better accuracy"
        : "Scan in Progress...";
    else if (timeElapsed <= 40000) return scanMessages[0];
    else if (timeElapsed <= 50000) return scanMessages[1];
    else if (timeElapsed <= 60000) return scanMessages[2];
    else if (timeElapsed <= 70000) return scanMessages[3];
    else if (timeElapsed <= 80000) return scanMessages[4];
    else if (timeElapsed <= 90000) return scanMessages[5];
    else if (timeElapsed <= 100000) return scanMessages[6];
    else if (timeElapsed <= 110000) return scanMessages[7];
    else if (timeElapsed <= 120000) return scanMessages[8];
    else if (timeElapsed <= 130000) return scanMessages[9];
    else if (timeElapsed <= 140000) return scanMessages[10];
  }
};

const ShowMessage = ({ type, timeElapsed, isLightMode }) => {
  return getMessage(type, timeElapsed, isLightMode);
};

export default ShowMessage;
