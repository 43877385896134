import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { fingerScan } from "careplix-web-algo";

import LoadingScreen from "../components/LoadingScreen";

import { ReactComponent as CarePlixLogo } from "../assets/icons/CarePlixLogo.svg";
import Failure from "../assets/images/failure.gif";
import HertBeat from "../assets/images/heartbeat.gif";
import Analyzing from "../assets/images/analyzing.gif";

const scanMessages = [
  "We burn calories while we are asleep because brain activity requires energy",
  "Exercise promotes cardiovascular health, ensuring more blood and oxygen to circulate the body, helping to elevate energy",
  "Always sleep on your back straight since it allows your neck and spine in a neutral position",
  "Alcohol consumption disrupts chemical impulses between brain cells causing impulsive behaviour, slurred speech, impaired memory",
  "Meditation increases nitric oxide generation in the brain helping to lower your blood pressure",
  "Meditation reduces the production of cytokines in several recent studies, thus helping in stress reduction",
  "Laughing is good for your heart. It reduces stress and gives a boost to your immune system",
  "Lack of sleep can lead to heart disease, heart attack, or stroke",
  "Exercise boosts brain performance",
  "Heart pumps about 2,000 gallons of blood every day",
  "Your heart will beat about 115,000 times each day",
].sort(() => Math.random() - 0.5);

const getMessage = (type = "", timeElapsed = 0) => {
  if (type !== "scan") {
    if (timeElapsed <= 5000) return "Cover your back camera with your finger";
    else if (timeElapsed <= 10000)
      return "During the measurement, please do not speak or move";
    else if (timeElapsed <= 17000)
      return "Keep your device steady throughout the vital measurement";
    else if (timeElapsed <= 18000) return "We are good to start in 3";
    else if (timeElapsed <= 19000) return "We are good to start in 2";
    else if (timeElapsed <= 20000) return "We are good to start in 1";
  } else {
    if (timeElapsed <= 30000) return "Scan in Progress...";
    else if (timeElapsed <= 40000) return scanMessages[0];
    else if (timeElapsed <= 50000) return scanMessages[1];
    else if (timeElapsed <= 60000) return scanMessages[2];
    else if (timeElapsed <= 70000) return scanMessages[3];
    else if (timeElapsed <= 80000) return scanMessages[4];
    else if (timeElapsed <= 90000) return scanMessages[5];
    else if (timeElapsed <= 100000) return scanMessages[6];
    else if (timeElapsed <= 110000) return scanMessages[7];
    else if (timeElapsed <= 120000) return scanMessages[8];
    else if (timeElapsed <= 130000) return scanMessages[9];
    else if (timeElapsed <= 140000) return scanMessages[10];
  }
};

const FingerScan = () => {
  const [analyzing, setAnalyzing] = useState(false);
  const [error, setError] = useState("");
  const [scanFrameData, setScanFrameData] = useState({
    type: "",
    timeElapsed: 0,
    confidence: 0,
    fps: 0,
  });

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.state?.scanToken?.length > 0) {
      fingerScan.onFrame((fd) => setScanFrameData(fd));
      fingerScan.onScanFinish(
        async ({ raw_intensity, ppg_time, average_fps }) => {
          try {
            setAnalyzing(true);
            const resp = await fetch(
              "https://sdk-staging.carenow.healthcare/vitals/add-scan",
              {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                  /*
          Fill in the following information about the user so that we can provide the complete list of vitals
          You can follow the provided swagger documentation for description on the parameters.
              */
                  api_key: "CNCP9jVaJogj3NOyOGY1",
                  scan_token: location.state.scanToken,
                  employee_id: "12345",
                  posture: location.state?.posture ?? "resting", // Resting | Walking | Standing | Exercising
                  dob: "date-of-birth", // yyyy-MM-dd
                  gender: "gender", // Male | Female | Other
                  metadata: {
                    physiological_scores: {
                      height: "height",
                      weight: "weight",
                    },
                    ppg_time: ppg_time,
                    raw_intensity: raw_intensity,
                    device: navigator.platform.match(/iPhone|iPod|iPad/)
                      ? "RPPG_CAREPLIX_FINGER_IOS"
                      : "RPPG_CAREPLIX_FINGER_ANDROID",
                    fps: average_fps,
                  },
                }),
              }
            );
            const resp_json = await resp.json();
            if (resp_json.statusCode?.toString().startsWith("2"))
              navigate("/scan-result", {
                state: { result: resp_json },
                replace: true,
              });
            else throw new Error(resp_json.message);
          } catch (err) {
            console.error(err);
            setError(err.message);
          }
        }
      );
      fingerScan.onError((err) => {
        console.error(err);
        setError(err.message);
      });
      fingerScan
        .startScan(60000, 60000)
        .then(() => console.log("Scan Started"))
        .catch(console.error);
    } else setError("Invalid Scan Token");
    return () => {
      fingerScan.stopScan(true);
    };
  }, [location, navigate]);

  return (
    <div className="relative h-screen bg-white">
      {error.length > 0 ? (
        <div className="absolute inset-0 bg-white px-8 py-12 flex flex-col items-center justify-center text-center">
          <img src={Failure} alt="failure icon" className="w-48 mx-auto" />
          <p className="mt-3 text-primary text-center">
            Scan Failed!
            <br />
            Please try again.
          </p>
          <button
            className="mt-8 px-4 py-2 rounded-lg bg-red-500 text-white text-sm font-medium"
            type="button"
            onClick={() => {
              navigate(-1);
            }}
          >
            Go Back
          </button>
        </div>
      ) : analyzing ? (
        <div className="absolute inset-0 bg-white px-8 py-12 flex flex-col items-center justify-center text-center">
          <img src={Analyzing} alt="analyzing icon" className="w-48 mx-auto" />
          <p className="mt-3 text-primary text-xl">Analyzing Data</p>
          <p className="mt-2 text-primary font-light">
            Hold tight, the measurement
            <br />
            results are on the way
          </p>
        </div>
      ) : (
        <>
          <div className="relative h-full w-full">
            <video
              className="fixed bottom-12 left-8 w-px h-px bg-white/80"
              id="videoInput"
              autoPlay
              muted
              playsInline
            />
            <canvas id="canvasOutput" className="h-full w-full -scale-x-100" />
            <div className="fixed inset-x-0 top-[30vh]">
              {fingerScan.isFingerInView() ? (
                <img src={HertBeat} alt="heartbeat" className="w-48 mx-auto" />
              ) : (
                <>
                  <svg className="w-48 mx-auto" viewBox="0 0 300 90">
                    <circle fill="#fff" cx="278.63" cy="55.48" r="13.33" />
                    <polyline
                      fill="none"
                      stroke="#fff"
                      strokeWidth={5}
                      strokeMiterlimit={10}
                      points="7.75,54.04 74.69,54.04 83.58,21.09 96.13,72.86 102.93,54.04 118.61,53.51 130.12,10.11 158.36,80.71 169.86,54.04 189.73,54.04 198.1,23.18 200.71,23.18 212.22,68.68 217.45,54.04 265.57,53.96"
                    />
                  </svg>
                  <p className="mt-2 text-xl text-center text-white drop-shadow">
                    Cannot Detect Finger
                  </p>
                </>
              )}
            </div>
            {fingerScan.canStop() && (
              <button
                type="button"
                onClick={() => fingerScan.stopScan()}
                className="fixed top-8 left-4 rounded-full px-3 py-1.5 text-xs text-white bg-[#ED1749]"
              >
                Finish Scan
              </button>
            )}
            <div
              className="fixed top-8 right-4 rounded-full px-3 py-1.5 text-xs text-white bg-[#f31e2e]"
              style={{
                backgroundColor:
                  scanFrameData.confidence > 0.5
                    ? scanFrameData.confidence > 0.9
                      ? "#a0dd0c"
                      : "#eea508"
                    : "#f31e2e",
              }}
            >
              Signal Strength:&nbsp;
              <span className="font-semibold">
                {scanFrameData.confidence > 0.5
                  ? scanFrameData.confidence > 0.9
                    ? "Good"
                    : "Moderate"
                  : "Poor"}
              </span>
            </div>
            <div className="fixed bottom-10 inset-x-6 p-6 rounded-xl bg-white/80 flex flex-col">
              <div className="flex flex-col items-center justify-center text-primary text-center text-xxs leading-tight">
                <CarePlixLogo className="shrink-0 h-[30px]" />
                <p>
                  by&nbsp;
                  <span className="text-xs font-semibold">
                    Carenow Healthcare
                  </span>
                </p>
                <p className="font-medium">CareNo-Kolka-WB/M/MD002063</p>
              </div>
              <h3 className="mt-3 text-primary font-semibold">
                {scanFrameData.type === "scan"
                  ? `${Math.round(
                      ((scanFrameData.timeElapsed - 20000) / 60000) * 100
                    )}% Completed...`
                  : `Calibration in progress...`}
              </h3>
              <h4 className="mt-1.5 text-primary text-xs">
                {getMessage(scanFrameData.type, scanFrameData.timeElapsed)}
              </h4>
              <button
                type="button"
                onClick={() => {
                  fingerScan.stopScan(true);
                  navigate(-1);
                }}
                className="self-end mt-4 text-[#ED1749] text-sm font-medium"
              >
                &lt;&nbsp;Cancel Scan
              </button>
            </div>
          </div>
          {fingerScan.isInitializing() && (
            <div className="absolute inset-0 bg-white px-8 py-12 flex flex-col items-center justify-center text-center">
              <LoadingScreen />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default FingerScan;
