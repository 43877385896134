import React from "react";

import { ReactComponent as NotInScreenImg } from "../../assets/icons/NotInScreen.svg";

const NotInScreen = () => {
  return (
    <div className="fixed inset-x-0 top-[20vh]">
      <NotInScreenImg />
      <p className="mt-2 text-xl text-center text-white drop-shadow">
        Cannot Detect Face
      </p>
    </div>
  );
};

export default NotInScreen;
