import { getDecryptedData } from "../api/hanlders";

export const handleResponse = async (response, isEncrypted) => {
  //   const contentType = response.headers.get("Content-Type");

  // if (contentType && contentType.indexOf('application/json') !== -1) {
  const jsonRes = await response.json();
  if (!isEncrypted) {
    return jsonRes;
  }
  const parsedResponse = await JSON.parse(getDecryptedData(jsonRes));
  //   console.log("response", response, parsedResponse);

  if (parsedResponse?.code === 1) {
    return parsedResponse;
  } else if (parsedResponse?.code === -1) {
    // DeviceEventEmitter.emit('session_expired');
    return parsedResponse;
  } else {
    return parsedResponse;
  }
  // } else {
  //     return response.text();
  // }
};

export const getQueryParams = (search) => {
  const queryParams = new URLSearchParams(search);
  return {
    employee_id: queryParams.get("employee_id"),
    userToken: queryParams.get("userToken"),
    posture: queryParams.get("posture"),
    gender: queryParams.get("gender"),
    height: queryParams.get("height"),
    dob: queryParams.get("dob"),
    weight: queryParams.get("weight"),
  };
};
